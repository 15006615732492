import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MasterComonent from '../../../../components/Backend/MasterComonent';
import LinkBtn from '../../../../components/inc/LinkBtn';
import SimpleLoading from '../../../../components/Loading/SimpleLoading';
import NavigationHeder from '../../../../components/Navigations/NavigationHeder';
import Helper from '../../../../inc/Helper';
import Settings from '../../../../inc/Settings';
import { ValidateArray } from '../../../../inc/Validation';
import CustomHtmlCell from '../../../../components/grid/ag/cellRender/CustomHtmlCell';
import AgGridMemo from '../../../../components/grid/ag/AgGridMemo';
import Button from '../../../../components/inc/Button';
import $ from 'jquery';
import Api from '../../../../inc/Api';

const Template = () => {

  const [loading, setLoading] = useState(true);
  const [templateData, setTemplateData] = useState([]);
  const [sourceName, setSourceName] = useState([]);
  const [forceRender, setForceRender] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getTemplateData();
  }, [])

  const [tenantId, setTenantId] = useState('');
  const getTenantId = () => {
    let api = Api;
    api.setUserToken()
    return new Promise((resolve, reject) => {
      resolve(
        api.axios().get(Settings.loginUrl + '/user_details').then((res) => {
          if (res.data.Status === "Success") {
            return (res.data.data.tenant_id);
          }
        })).catch((error) => reject(
          console.log(error)
        ))
    })
  }

  const getModuleData = async () => {
    const tenantId = await getTenantId();
    setTenantId(tenantId);
    return new Promise((resolve, reject) => {
      resolve(
        axios.get(Settings.apiUrl + `/source?for_workflow=${true}`).then((res) => {
          if (res.data.status === "success") {
            return res.data.data;
          }
        })).catch((res) => reject(
          console.log(res)
        ))
    })
  }

  const getTemplateData = async () => {
    setLoading(true);
    const module = await getModuleData();
    let moduleData = [];
    module.forEach((item) =>
      moduleData.push({
        value: item.source_id,
        label: item.source_name
      })
    )
    setSourceName(moduleData);
    axios.get(Settings.baseUrl + 'template/get-all').then((res) => {
      if (res.data.success) {
        const templateData = res.data.data;
        const newtemplateDataArry = templateData.map((item) => {
          const itemM = module.filter((fItem) => fItem.source_id === item.module_id)[0];
          return {
            ...item,
            ...itemM,
            viewOrEdit: 'Modify'
          }
        });
        setTemplateData(newtemplateDataArry);
        setForceRender(!forceRender);
        setLoading(false);
      }
    }).catch((error) => {
      console.log(error);
      setLoading(false);
    })
  }

  const onSaveClick = (rowItem) => {
    let updatedData = []
    let newData = [];
    rowItem.forEach(item => {
      if (!item.template_id || item.template_id <= 0) {
        newData.push(item);
      } else {
        updatedData.push(item);
      }
    });
    if (newData.length >= 1) {
      addTemplate(newData);
    }
    if (updatedData.length >= 1) {
      updateTemplate(updatedData);
    }
  }

  const addTemplate = (data) => {
    let validation = ValidateArray(data);
    if (validation) {
      Helper.alert('Please Enter Data In Fields!', 'error');
      getTemplateData();
    }
    const formData = [];
    data.forEach((item) => {
      formData.push({
        "tenant_id": tenantId,
        "module_id": item.source_name,
        "name": item.name,
        "description": item.description,
      })
    })
    setLoading(true);
    axios.post(Settings.baseUrl + 'template/create', formData).then((res) => {
      if (res.data.success) {
        Helper.alert(res.data.message);
        setLoading(false);
        getTemplateData();
      }
    }).catch((res) => {
      Helper.alert(res.response.data.errors[0].module_id[0], 'error')
      setLoading(false);
    })
  };

  const updateTemplate = (data) => {
    let validation = ValidateArray(data);
    if (validation) {
      Helper.alert('Please Enter Data In Fields!', 'error');
      getTemplateData();
    }
    const formData = [];
    data.forEach((item) => {
      formData.push({
        "template_id": item.template_id,
        "field_dictionary": [],
        "module_id": item.source_id,
        "name": item.name,
        "description": item.description,
      })
    })
    axios.post(Settings.baseUrl + 'template/update', formData).then((res) => {
      if (res.data.success) {
        Helper.alert(res.data.message);
        getTemplateData();
        setLoading(false);
      }
    }).catch((error) => {
      console.log(error);
      setLoading(false);
    })
  }

  const deleteTemplate = (getTemplateId) => {
    setLoading(true);
    let formData = [];
    getTemplateId.forEach((item) => {
      formData.push(item.template_id)
    });
    axios.post(Settings.baseUrl + 'template/delete', { "template_id": formData }).then((res) => {
      if (res.data.success) {
        Helper.alert(res.data.message);
        getTemplateData();
        setLoading(false);
      }
    }).catch((error) => {
      console.log(error);
      setLoading(false);
    })
  }

  const gridLinkValueFormat = (params) => {
    return `<div class="rs_active_label">${params.value ? params.value : 'Modify'}</div>`;
  }

  const gridValidationRules = {
    name: { name: 'name', displayName: 'Name', types: ['Required'] },
    source_name: { name: 'source_name', displayName: 'Module Name', types: ['Required'] }
  }

  const Template_Tabele_Columns_Headings = [
    { field: 'name', headerName: 'Name', validationRule: gridValidationRules.name },
    { field: 'source_name', cellDataType: false, headerName: 'Module Name', inputType: "dropdown", options: sourceName, validationRule: gridValidationRules.source_name },
    { field: 'description', headerName: 'Description' },
    { field: 'viewOrEdit', headerName: 'Action', editable: false, cellRenderer: CustomHtmlCell, valueFormatter: gridLinkValueFormat },
  ]

  const getValue = (event) => {
    if (event.colDef.field === "viewOrEdit") {
      if (event.data.template_id === 0) return;
      navigate(`/template/edit?id=${event.data?.template_id}`);
    };
  }

  const saveHandler = () => {
    $('.rs_form_grid .rs_grid_btn_save').trigger('click');
  }

  return (
    <>
      <MasterComonent>
        <NavigationHeder hideBackButton={true}>
          <LinkBtn to={'/template/edit'} title='Add Template With Fields' />
          <Button isActive={true} title='Save' onClick={saveHandler} icon={'/images/icons/save-white.svg'} />
        </NavigationHeder>
        {loading ? <SimpleLoading /> : null}
        <div className='rs_form_grid'>
          <AgGridMemo data={templateData} id="rs_users_list" header={Template_Tabele_Columns_Headings} onDelete={deleteTemplate} onSave={onSaveClick} onCellClicked={getValue} forceRender={forceRender} />
        </div>
      </MasterComonent>
    </>
  )
}
export default Template;