import React, { useState } from 'react';
import NavigationHeder from '../../../components/Navigations/NavigationHeder';
import Settings from '../../../inc/Settings';
import axios from 'axios';
import Helper from '../../../inc/Helper';
import $ from 'jquery';
import AgGridNew from '../../../components/grid/ag/ag-grid-new';
import MasterComponentWraper from '../../../components/Backend/MasterComponentWraper';
import { connect } from 'react-redux';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';

let gridApi = null;

const Instances = (props) => {
  const [selectedRowInstance, setSelectedRowInstance] = useState(null);

  const restartWorkflow = () => {
    if (!selectedRowInstance) {
      Helper.alert("Please select a workflow for restart", 'error');
      return;
    };

    axios.post(Settings.baseUrl + `instance/restart`, { 'instance_id': selectedRowInstance }).then((res) => {
      if (res?.data?.status === 200) {
        Helper.alert(res?.data?.message);
        gridApi?.refreshServerSide({ purge: true });
        gridApi?.deselectAll();
      };
    }).catch((error) => {
      getPopupMessageBasedOnStatus(error);
    })
  };

  const RefreshFailedScenariosBtn = () => {
    return (
      <div className='common-grid-responsive-btns'> 
        <button disabled={!selectedRowInstance} className={`rs_h_controller rs_setvalue_btn visible_on_select_item ${!selectedRowInstance ? 'ctl-dissable' : ''}`} onClick={restartWorkflow}>
          <img src='/images/icons/refresh-cw.svg' />
          <span>Restart</span>
        </button>
      </div>
    )
  };

  const onSelectionChangedInstances = (event) => {
    let currentNode = event.api.getSelectedRows();

    if (currentNode?.length <= 0 || !currentNode[0]?.instance_id){
      setSelectedRowInstance(null);
      $('.rs_setvalue_btn').addClass('ctl-dissable');
      $('.visible_on_select_item').addClass('ctl-dissable'); 
    } else {
      setSelectedRowInstance(currentNode[0]?.instance_id);
      $('.rs_setvalue_btn').removeClass('ctl-dissable');
      $('.visible_on_select_item').removeClass('ctl-dissable'); 
    } 
  };

  const gridColumnsFailedInstances = [
    { field: 'workflow_name', headerName: 'Workflow name', minWidth: 350 },
    { field: 'template_name', headerName: 'Template name', minWidth: 250 },
    { field: 'document_number', headerName: 'Document number', minWidth: 250 },
    { field: 'workflow_version', headerName: 'Versions' },
    { field: 'trigger', headerName: 'Trigger option' },
    { field: 'reason', headerName: 'Reason for failed', minWidth: 850 },
    { field: 'module_item_id', headerName: 'Integrator' },
    { field: 'status', headerName: 'Status' }
  ];

  return (
    <> 
      <NavigationHeder hideBackButton={true} title={`<span style="color:#000;"> Home > </span>  ${'Failed Instances'}`} />
 
      <AgGridNew
        apiUrl={Settings.baseUrl + `instance/failed`} 
        hideAddBtn={true}
        hideDeleteBtn={true}
        onSelectionChanged={onSelectionChangedInstances} 
        rowType={'single'}  
        gridId={'workflow_failed_instances_grid'}
        columnDefs={gridColumnsFailedInstances} 
        apiTokenRequired={false}
        onGridReady={ event => gridApi = event?.api }
        height={500}
        additionalParams={`?tenant_id=${props?.user?.tenant_id}`} // change this ? to & once pagination is true
        filterAdditionalParams={`?tenant_id=${props?.user?.tenant_id}`} // change this ? to & once pagination is true
        beforeDeleteBtns={<RefreshFailedScenariosBtn />}  
        pagination={false}  
      /> 
    </>
  )
};

const mapStateToProps = (state) => {
  return { 
    user: state.auth.user
  }
};

export default connect(mapStateToProps) (MasterComponentWraper(Instances));