import React, { useEffect, useRef, useState } from 'react'
import Button from '../../../../../components/inc/Button';
import SimpleLoading from '../../../../../components/Loading/SimpleLoading';
import Popup from '../../../../../components/Popup/Popup';
import axios from 'axios';
import Settings from '../../../../../inc/Settings';
import Input from '../../../../../components/Forms/Input';
import Helper from '../../../../../inc/Helper';
import { Tab, Tabs } from 'react-bootstrap';
import Collapse from '../../../../../components/inc/Collapse';
import AgGridMemo from '../../../../../components/grid/ag/AgGridMemo';
import AssignmentType from '../Assignment/Assignment Type/AssignmentType';
import TimeLimit from '../Assignment/Time limit/TimeLimit';
import User from '../Assignment/User/User';
import CompletionPolicy from '../Assignment/Completion Policy/CompletionPolicy';
import DropDownInput from '../../../../../components/Forms/Dropdown';
import './Task.css';
import Checkbox from '../../../../../components/Forms/Checkbox';
import ToggleSwitch, { TitlePosition } from '../../../../../components/Forms/ToggleSwitch';
import Api from '../../../../../inc/Api';
import Escalation from '../Escalation/Escalation';
import { connect } from 'react-redux';

let selectedUserData = null;
const Task = (props) => {
  const query = new URLSearchParams(window.location.search);
  const workflowBodyId = query.get('to_id');
  const getNodeId = () => `${+new Date()}`;
  const [loading, setLoading] = useState(false);
  const [nodeLabel, setnodeLabel] = useState('');
  const [nodeIsEditable,setNodeIsEditable]=useState(false)
  const [forceRender, setForceRender] = useState(false);
  const [actionNameData, setActionNameData] = useState([]);
  const [btnDisable,setbtnDisable]=useState(false)

  useEffect(() => {
    if (props.onClickNode) {
      getTaskData();
      // checkInstance();
    }
  }, [])

  const checkInstance = () => {
    return new Promise((resolve, reject) => {
      resolve(
        axios.get(Settings.baseUrl + `instance/check-by-body-id?body_id=${workflowBodyId}`).then((res) => {
          if (res.status === 200) {
            return res.data.instance_exist;
          }
        }
        )).catch((res) => reject(
          console.log(res)
        ))
    })
  }

  const actionName = [
    { value: 1, label: 'Approve' },
    { value: 2, label: 'Reject' },
    { value: 3, label: 'Request more information' },
    { value: 4, label: 'Delegate' },
    { value: 5, label: 'Send back' },
    { value: 11, label: 'Complete' },
    // { value: 6, label: 'Recall' },
    // { value: 7, label: 'Escalation - Task' },
    // { value: 8, label: 'Escalation - Email' },
    // { value: 9, label: 'Reminder' }
  ]


  const Action_Tabele_Columns_Headings = [
    { field: 'label', headerName: 'Action' },
    { field: 'description', headerName: 'Description' },
  ]

  const [completionPolicy, setCompletionPolicy] = useState();
  const [actionTaskData, setActionTaskData] = useState();
  const [assignmentType, setAssignmentType] = useState(null);
  const [userType, setUserType] = useState('Entity');
  const [timeLimitType, setTimeLimitType] = useState(null);
  const [timeLimit, setTimeLimit] = useState(null);
  const [escalationTime, setEscalationTime] = useState(null);
  const [escalation, setEscalation] = useState([]);

  const userTypeHandler = (assignmentType) => {
    switch (assignmentType) {
      case 1:
        setUserType('User')
        break;
      case 2:
        setUserType('Team');
        break;
      case 3:
        setUserType('Position');
        break;
      case 4:
        setUserType('Hierarchy');
        break;
      case 5:
        setUserType('Dynamic Team');
        break;
    }
  }

  useEffect(() => {
    userTypeHandler(assignmentType);
    // setUserData([]);
  }, [assignmentType])

  const getTaskData = () => {
    setLoading(true);
    axios.get(Settings.baseUrl + `workflow-task/get?body_id=${workflowBodyId}&node_id=${props.onClickNode}`).then((res) => {
      if (res.data.success) {
        console.log(res.data.data);
        const data = res.data.data.stage;
        setCompletionPolicy(res.data.data.completion_policy);
        setActionTaskData(res.data.data);
        setAssignmentType(res.data.data.assignee_type);
        setStage(res.data.data.stage_id);
        userTypeHandler(res.data.data.assignee_type);
        setPercenatge(res.data.data.percentage_of_approver);
        setApproversType(res.data.data.completion_policy);
        setTimeLimitType(res.data.data.duration_type);
        setTimeLimit(res.data.data.completion_time);
        setEscalationTime(res.data.data.escalation_time);
        setActionNameData(res.data.data.allowed_actions);
        setEscalation(res.data.data.escalation); 
        setUserData(res.data.data.assignee);
        setNodeIsEditable(res.data.data.is_editable) 
        
        selectedUserData = res.data.data.assignee;
      }
      setLoading(false);
    }).catch((error) => {
      console.log(error);
      setLoading(false);
      Helper.alert(error.message + "!", 'error');
    })
  }

  const modalClose = () => {
    setbtnDisable(false)
    props.hideTaskModal(false);
    props.setOnClickNode();
  }

  const nodeDeleteHandler = async () => {
    setbtnDisable(true)
    const value = await checkInstance();
    if (value) {
      Helper.alert("This workflow have instance, so you can't delete", 'error');
      setbtnDisable(false)
    } else {
      const formData = { "node_id": props.onClickNode };
      axios.post(Settings.baseUrl + 'workflow-node/delete', formData).then((res) => {
        if (res.data.success) {
          Helper.alert(res.data.message);
          props.setDelete(true);
          modalClose();
        }
      }).catch((error) => {
        setbtnDisable(false)
        console.log(error)
      })
    }
  }

  const [allowActionData, setAllowActionData] = useState([]);
  const [stage, setStage] = useState(null);
  const [userData, setUserData] = useState([]);
  const [approversType, setApproversType] = useState(null);
  const [percenatge, setPercenatge] = useState('');

  const getData = (event) => {
    const data = event.api.getSelectedRows();
    const arrayData = []
    data.forEach((item) => {
      arrayData.push(item.value);
    })
    setAllowActionData(arrayData);
  }
  const [functionToCall, createFunctionToCall] = useState(() => () => { })

  const childCompRef = useRef();

  const dataHandler = () => {
    let escalationData;
    let isFormData = false;
    let parentCondition;
    if (props.currentHandleId === 'a') {
      parentCondition = true;
    } else {
      parentCondition = false;
    }
    if (allowActionData.length) {
      if (stage) {
        if (assignmentType) {
          if (userData.length || assignmentType===6 || assignmentType === 7 || assignmentType ===8) {
            if ((timeLimit > 0 || escalationTime > 0) && (timeLimitType === null || timeLimitType === 0)) {
              Helper.alert("Duration type is required for time limit", 'error');
            } else if ((timeLimitType > 0)  && (timeLimit === 0 || timeLimit === null)) {
              Helper.alert("Timelimit data is required", 'error');
              return;
            } else if ((timeLimit > 0 && escalationTime > 0) && (timeLimit >= escalationTime)) {
              Helper.alert("Escalation time should be greater than or equal to completion time", 'error');
            } else {
              if (assignmentType !== 1 && assignmentType !==3 && assignmentType !==6 && assignmentType !== 7 && assignmentType !==8 && !approversType) {
                Helper.alert("Completion policy required in assignment", 'error');
              }
              else if (approversType === "3" && !percenatge) {
                Helper.alert("Percentage is required in assignment", 'error');
              } else {
                if (nodeLabel) {
                  if (escalationTime > 0 && timeLimitType > 0) {
                    escalationData = childCompRef.current.handleSubmit();
                    if (escalationData?.length) {
                      isFormData = true;
                    }
                  } else {
                    isFormData = true;
                  }
                } else {
                  Helper.alert("Label are required field for task node", 'error');
                }
              }
            }
          } else {
            Helper.alert("User Data is required", 'error');
          }
        } else {
          Helper.alert("Assignment type is required in assignment tab", 'error');
        }
      } else {
        Helper.alert("Stage is required in stages tab", 'error');
      }
    } else {
      Helper.alert("Allow action data is required", 'error');
    }

    const nodid = getNodeId();
    let formData = {
      "body_id": workflowBodyId,
      "node_id": nodid,
      "stage_id": stage,
      "parent_node_id": props.currentNode !== "1" ? +props.currentNode : null,
      "parent_condition": props.currentHandleId ? parentCondition : null,
      "is_editable":nodeIsEditable,
      "assignment": {
        "allowed_actions": allowActionData,
        "assignee_type": assignmentType,
        "assignee": userData,
        "duration_type": timeLimit > 0 || escalationTime > 0 ? timeLimitType : null,
        "completion_time": timeLimit > 0 ? timeLimit : null,
        "escalation_time": escalationTime ? escalationTime : null,
        "completion_policy": assignmentType !== 1 ? approversType : null,
        "percentage_of_approver": assignmentType !== 1 && approversType === "3" ? percenatge : null
      },
      "escalation": escalationTime > 0 ? escalationData : []
    }
    if (isFormData) {
      setbtnDisable(true)
      axios.post(Settings.baseUrl + 'workflow-task/create', formData).then((res) => {
        if (res.data.success) {
          setbtnDisable(false)
          Helper.alert(res.data.message);
          props.hideTaskModal(false);
          props.mainModal(false);
          props.addNode(props.currentNode, props.actionValue, props.currentHandleId, nodid, nodeLabel);
          setTimeout(() => { props.save() }, 100);
        } else {
          setbtnDisable(false)
          Helper.alert("First fill the all field data");
        }
      }).catch((error) => {
        setbtnDisable(false)
        if (!error.response.data.success) {
          Helper.alert("Something went wrong, please try after some time", 'error');
        }
      })
    }
  }

  const nodTaskUpdate = async () => {
    const value = await checkInstance();
    if (value) {
      Helper.alert("This workflow have instance, so you can't edit", 'error');
      getTaskData();
    } else {
      let escalationData;
      if (!allowActionData.length) {
        Helper.alert("Allow action data is required", 'error');
        return;
      }
      if (!userData.length && assignmentType !== 6 && assignmentType !==7 && assignmentType !==8) {
        Helper.alert("User Data is required", 'error');
        return;
      } // adding this (&& assignmentType !== 3) check for removing the validation coming when position is selected
      if (assignmentType !== 1 && assignmentType !== 3 && assignmentType !== 6 && assignmentType !==7 && assignmentType !==8 && !approversType) {
        Helper.alert("Completion policy required", 'error');
        return;
      }
      if (approversType === "3" && !percenatge) {
        Helper.alert("Percentage is required", 'error');
        return;
      }
      if ((timeLimitType > 0 )  && (timeLimit === 0 || timeLimit === null)) {
        Helper.alert("Timelimit data is required", 'error');
        return;
      }
      if ((timeLimit > 0 && escalationTime > 0) && (timeLimit >= escalationTime)) {
        Helper.alert("Escalation time should be greater than or equal to completion time", 'error');
        return;
      }
      if (escalationTime > 0) {
        escalationData = childCompRef.current.handleSubmit();
        if (!escalationData?.length) {
          return;
        }
      }
      let formData = {
        "node_id": props.onClickNode,
        "allowed_actions": allowActionData,
        "assignee_type": assignmentType,
        "assignee": userData,
        "stage_id": stage,
        "duration_type": timeLimit > 0 || escalationTime > 0 ? timeLimitType : null,
        "completion_time": timeLimit > 0 ? timeLimit : null,
        "escalation_time": escalationTime ? escalationTime : null,
        "completion_policy": assignmentType !== 1 ? approversType : null,
        "percentage_of_approver": assignmentType !== 1 && approversType === "3" ? percenatge : null,
        "escalation": escalationTime > 0 ? escalationData : [],
        "is_editable": nodeIsEditable,
      }
      setbtnDisable(true)
      axios.post(Settings.baseUrl + 'workflow-task/update', formData).then((res) => {
        if (res.data.success) {
          setbtnDisable(false)
          Helper.alert(res.data.message);
        }
      }).catch((error) => {
        setbtnDisable(false)
        if (!error.response.data.success) {
          Helper.alert("Something went wrong, please try after some time", 'error');
        }
      })
    }
  }

  const onFirstDataRendered = (params) => {
    params.api.forEachNode((node) => {
      const data = node.data.value;
      if (actionNameData.includes(data)) {
        node.setSelected(true);
      }
    });
  }

  useEffect(() => {
    getStagesHandler();
  }, [])

  const [stagesData, setStagesData] = useState([]);
  const getStagesHandler = () => {
    const formData = {};
    axios.get(Settings.baseUrl + `stage/get/${props.userData?.tenant_id}`, formData).then((res) => {
      if (res.data.success) {
        console.log(res.data.data);
        const data = res.data.data;
        const stages = []
        data.forEach((item) => {
          stages.push({
            "value": item.stage_id,
            "label": item.name
          })
        })
        setStagesData(stages);
      }
    }).catch((error) => {
      if (!error.response.data.success) {
        Helper.alert("Something went wrong, please try after some time", 'error');
      }
    })
  }

  const [tabActiveKey, setTabActiveKey] = useState('basic_settings');

  const onChangeHandler = (name,value) => {
    if(value == "on") {
      setNodeIsEditable(true)
    }else {
      setNodeIsEditable(false)
    }
  }

  return (
      <Popup onClose={modalClose} autoOpen={true} >
        <h3>Task</h3>
        <p>This is a approval task</p>
          <div className='d-flex gap-3 align-items-center'>
          {!props.onClickNode && <Input label="Label" className="col-8" onChange={(e) => { setnodeLabel(e.target.value) }} required value={nodeLabel} />}
           <Checkbox label="Is editable" onChange={onChangeHandler} value={nodeIsEditable} isChecked={nodeIsEditable} />
          </div>
        {loading ? <SimpleLoading /> : null}
        <Tabs activeKey={tabActiveKey} onSelect={(k) => setTabActiveKey(k)} className="mb-3 conditional_tab_menu">
          <Tab eventKey="basic_settings" title="Advanced settings" tabClassName="conditional_tab_item" >
            <Collapse title='Action allowed for this workflow' open={true}>
              {
                !loading &&
                <AgGridMemo
                  id="rs_users_list"
                  data={actionName}
                  header={Action_Tabele_Columns_Headings}
                  height={"300px"}
                  allBtnHide={true}
                  hideHeaderRightContents={true}
                  viewOnly={true}
                  hideFooter={true}
                  onSelectionChanged={getData}
                  forceRender={forceRender}
                  onFirstDataRendered={props.onClickNode ? onFirstDataRendered : ""}
                />
              }
            </Collapse>
          </Tab>
          <Tab eventKey="stages" title="Stages" tabClassName="stages_tab_item" >
            <div className="card mb-3">
              <h5 className="card-header text-primary border-bottom">Assign stages for this task</h5>
              <div className="card-body">
                <div className="p-2">
                  <div className='row'>
                    <div className='row label-form'>
                      <div className='col-lg-6 col-md-3'>
                        <DropDownInput label="Stage" required options={stagesData} onChange={(e) => { setStage(+e.target.value) }} value={stage} id='stage_id' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="advanced_settings" title="Assignment" tabClassName="conditional_tab_item" >
            {tabActiveKey === "advanced_settings" &&
              <Tabs
                variant='pills'
                className="mb-3 assignment_tab_menu"
              >
                <Tab eventKey="assignment_type" title="Assignment type" tabClassName='assignment_tab_item' >
                  <AssignmentType actionTaskData={actionTaskData} getAssignParticipantData={setAssignmentType} assigneeType={assignmentType}/>
                </Tab> 
                {[1, 2, 3, 4, 5].includes(assignmentType) ? <Tab eventKey="user" title={userType} tabClassName='assignment_tab_item' >
                  <User actionTaskData={actionTaskData} selectedUser={selectedUserData} userEntityData={setUserData} assignmentType={assignmentType} />
                </Tab> : ""}
                <Tab eventKey="time_limit" title="Time limit" tabClassName='assignment_tab_item' >
                  <TimeLimit actionTaskData={actionTaskData} getTimeLimit={setTimeLimit} getEscalationTime={setEscalationTime} getTimeLimitType={setTimeLimitType} timeLimitType={timeLimitType} timeDuration={timeLimit} escalationTime={escalationTime} />
                </Tab >
                {[2, 4, 5].includes(assignmentType)  ?
                  <Tab eventKey="completion_policy" title="Completion policy" tabClassName='assignment_tab_item' >
                    <CompletionPolicy completionPolicy={completionPolicy} actionTaskData={actionTaskData} getApproversType={setApproversType} setPercenatge={setPercenatge} percenatgeValue={percenatge} approversType={approversType} />
                  </Tab>
                  : ""}
              </Tabs>
            }
          </Tab>
          {escalationTime > 0 &&
            <Tab eventKey="escalation" title="Escalation" tabClassName="escalation_tab_item" >
              {/* {tabActiveKey === "escalation" && */}
              <Escalation ref={childCompRef} escalation={escalation} />
              {/* } */}
            </Tab>
          }
        </Tabs>
        <div className='d-flex justify-content-end mt-4 '>
          <Button className="me-1" title="Close" onClick={modalClose} />
          {props.onClickNode && tabActiveKey !== "escalation" && <Button isDisable={btnDisable} className="mx-1" title="Delete" onClick={nodeDeleteHandler} />}
          <Button isDisable={btnDisable} className="ms-1" title='Save' icon={'/images/icons/save-white.svg'} isActive={true} onClick={props.onClickNode ? nodTaskUpdate : dataHandler} />
        </div>
      </Popup >
  )
}


const mapStateToProps = (state) => {
  return {
    userData: state.auth.user,
  };
};

export default connect(mapStateToProps)(Task);
