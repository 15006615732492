import React, { useEffect, useState } from 'react'
import AgGridMemo from '../../../../../../components/grid/ag/AgGridMemo';
import Api from '../../../../../../inc/Api';
import Settings from '../../../../../../inc/Settings';
import Pagination from './Pagination';
import SimpleLoading from '../../../../../../components/Loading/SimpleLoading';
import DropDownInput from '../../../../../../components/Forms/Dropdown';

let gridApiUser = null;
let gridApiPosition = null;
let gridApiTeam = null;

const User = (props) => {

  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState([]);
  const [teamsData, setTeamsData] = useState([]);
  const [positionData, setPositionData] = useState([]);
  const [hierarchyData, setHierarchyData] = useState([]);
  const [hierarchyType, sethierarchyType] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [forceRender, setForceRender] = useState(false);
  const [totalRecord, setTotalRecord] = useState(null);

  useEffect(() => {
    setPageNumber(1);
    if (props.assignmentType === 1) {
      getUserData(1);
    }
    if (props.assignmentType === 2) {
      getTeamsData(1,false);
    }
    if (props.assignmentType === 3) {
      getUserDataASPerPosition();
    }
    if (props.assignmentType === 4) {
      getWorkflowHierarchyType();
    }
    if (props.assignmentType === 5) {
      getTeamsData(1,true);
    }
  }, [props.assignmentType])

  const getUserData = (pageNumber) => {
    setLoading(true);
    setTotalRecord(null);
    let api = Api;
    api.setUserToken()
    const formData = { "name": null }
    api.axios().post(Settings.loginUrl + `/workflow/get_users/${pageNumber}/10`, formData).then((res) => {
      if (res.data.status === "Success") {
        setTotalRecord(res.data.total_records);
        const data = res.data.data;
        const newArray = [];
        data.forEach((item) => {
          newArray.push({
            ...item,
            "id": item.user_id
          })
        })
        setForceRender(!forceRender)
        setUserData(newArray);
        setLoading(false);
      }
    }).catch((error) => {
      console.log(error);
      setLoading(false);
    }
    )
  }

  const [teamLoading, setTeamLoading] = useState(false);
  const getTeamsData = (pageNumber,isDynamicTeam) => {
    setTeamLoading(true);
    setTeamsData([]);
    setTotalRecord(null);
    let api = Api;
    api.setUserToken();
    api.axios().get(Settings.loginUrl + `/teamheader?dynamic_team_header=${isDynamicTeam}`).then((res) => {
      if (res.data.status === "success") {
        const data = res.data.data;
        setTotalRecord(res.data.total_records);
        const newArray = [];
        data.forEach((item) => {
          newArray.push({
            ...item,
            "id": item.teamheader_id
          })
        })
        setTeamsData(newArray);
        setForceRender(!forceRender);
        setTeamLoading(false);
      }
    }).catch((error) => {
      setTeamLoading(false);
      console.log(error);
    })
  }

  const [newPositionData, setNewPositionData] = useState([]);
  const getUserDataASPerPosition = (pageNumber=1) => {
    let api = Api;
    api.setUserToken();
    setTotalRecord(null);
    api.axios().get(Settings.apiOrgUrl + `/EMPosition?page_number=${pageNumber}&page_size=${10}`).then((res) => {
      if (res.data.status === "success") {
        const data = res.data.data;
        const newArrays = [];
        if (props.actionTaskData) {
          const saveData = props.actionTaskData.assignee;
          // saveData.map((item) => {
          //   const newData = data.filter((fItem) => fItem.position_id === item)[0];
          //   newArrays.push(newData);
          // })
          setNewPositionData(saveData);
        }
        const newArray = [];
        setTotalRecord(res?.data?.total_records);
        data.forEach((item) => {
          newArray.push({
            ...item,
            "id": item.position_id
          })
        })
        setPositionData(newArray);
        setForceRender(!forceRender)
      }
    }).catch((error) => {
      console.log(error);
    })
  }
  
  const [hierarchyTypeData, setHierarchyTypeData] = useState([]);
  const getWorkflowHierarchyType = () => {
    let api = Api;
    api.setUserToken()
    api.axios().get(Settings.loginUrl + `/enum/workflow_hierarchy_type`).then((res) => {
      if (res.data.status === "success") {
        const newArray = [];
        res.data.data.forEach((item) => {
          newArray.push({
            value: item.id,
            label: item.name
          })
        })
        setHierarchyTypeData(newArray);
      }
    }).catch((error) => {
      console.log(error);
    }
    )
  }

  const getWorkflowHierarchyTypeData = (e) => {
    console.log(e.target.value);
    sethierarchyType(e.target.value)
    // setHierarchyData();
  }

  const User_Table_Column_Headings = [
    { field: 'user_id', headerName: 'Id' },
    { field: 'email_address', headerName: 'Email' },
    { field: 'employee_display_name', headerName: 'Name' }
  ]

  const Team_Table_Column_Headings = [
    { field: 'teamheader_id', headerName: 'Id' },
    { field: 'name', headerName: 'Name' }
  ]

  const Group_Table_Column_Headings = [
    { field: 'position_id', headerName: 'Position Id' },
    { field: 'name', headerName: 'Group Name' },
    { field: 'code', headerName: 'Code' }
  ]

  const Hierarchy_Table_Column_Headings = [
    { field: 'hierarchy_id', headerName: 'Hierarchy Id' },
    { field: 'name', headerName: 'Name' },
    { field: 'code', headerName: 'Code' }
  ]

  const getData = (event) => {
    const data = event.api.getSelectedRows();
    const arrayData = []
    data.forEach((item) => {
      arrayData.push(item.id);
    })
    props.userEntityData(arrayData);
  }

  const makeGridRowSelected = (params) => { 
    let existData = props?.selectedUser; 
    setTimeout(() => {
      params?.api?.forEachNode((node) => {
        const data = node?.data?.id;
        if (existData?.includes(data)) {
          node.setSelected(true);
        }
      });
    }, 500);
  }

  const onFirstDataRendered = (params) => { 
    makeGridRowSelected(params);
  }

  const goPageByNumberUser = (pageNumber) => {
    setPageNumber(pageNumber);
    getUserData(pageNumber);
    makeGridRowSelected(gridApiUser); 
  };

  const goPageByNumberTeam = (pageNumber) => {
    setPageNumber(pageNumber);
    getTeamsData(pageNumber,props.assignmentType===5);
    makeGridRowSelected(gridApiTeam); 
  };

  const goPageByNumberPosition = (pageNumber) => {
    setPageNumber(pageNumber);
    getUserDataASPerPosition(pageNumber);
    makeGridRowSelected(gridApiPosition); 
  };

  return (
    <>
      {props.assignmentType === 1 && userData.length ? (
        <>
          {loading ? <SimpleLoading /> : null}
          <AgGridMemo onFirstDataRendered={props.selectedUser && props.selectedUser.length ? onFirstDataRendered : ""} onGridReady={(params) => gridApiUser = params} data={userData} forceRender={forceRender} header={User_Table_Column_Headings} height={"300px"} allBtnHide={true} onSelectionChanged={getData} viewOnly={true}  hideFooter={true} />
          {/* <AgGridMemo data={userData} header={User_Table_Column_Headings} height={"300px"} allBtnHide={true} onSelectionChanged={getData} viewOnly={true} onFirstDataRendered={props.actionTaskData && props.actionTaskData.assignee.length ? onFirstDataRendered : ""} hideFooter={true} /> */}
          <Pagination totalPages={Math.ceil(totalRecord / 10)} currentPage={pageNumber} onPageChange={goPageByNumberUser} totalRecord={totalRecord} />
        </>
      ) : null}
      {(props.assignmentType === 2 || props.assignmentType === 5 ) && teamsData.length ? (
        <>
          {!teamsData.length ? <SimpleLoading /> : null}
          <AgGridMemo onGridReady={(params) => gridApiTeam = params} data={teamsData} header={Team_Table_Column_Headings} height={"300px"} allBtnHide={true} onSelectionChanged={getData} viewOnly={true} singleRowSelect={true} onFirstDataRendered={props.selectedUser && props.selectedUser.length ? onFirstDataRendered : ""} hideFooter={true} forceRender={forceRender} />
          {/* <AgGridMemo data={teamsData} header={Team_Table_Column_Headings} height={"300px"} allBtnHide={true} onSelectionChanged={getData} viewOnly={true} singleRowSelect={true} onFirstDataRendered={props.actionTaskData && props.actionTaskData.assignee.length ? onFirstDataRendered : ""} hideFooter={true} forceRender={forceRender} /> */}
          <Pagination totalPages={Math.ceil(totalRecord / 10)} currentPage={pageNumber} onPageChange={goPageByNumberTeam} totalRecord={totalRecord} />
        </>
      ) : null}
      {props.assignmentType === 3 && (positionData.length || newPositionData.length) ? (
        <>
          <AgGridMemo onGridReady={(params) => gridApiPosition = params} data={positionData} forceRender={forceRender} header={Group_Table_Column_Headings} height={"300px"} allBtnHide={true} onSelectionChanged={getData} viewOnly={true} singleRowSelect={true} onFirstDataRendered={props.selectedUser && props.selectedUser.length ? onFirstDataRendered : ""} hideFooter={true} />
          <Pagination totalPages={Math.ceil(totalRecord / 10)} currentPage={pageNumber} onPageChange={goPageByNumberPosition} totalRecord={totalRecord} />
          {/* <AgGridMemo data={positionData} header={Group_Table_Column_Headings} height={"300px"} allBtnHide={true} onSelectionChanged={getData} viewOnly={true} singleRowSelect={true} onFirstDataRendered={props.actionTaskData && props.actionTaskData.assignee.length ? onFirstDataRendered : ""} /> */}
        </>
      ) : null}
      {
        props.assignmentType === 4 ?
          <>
            <div className="card-body">
              <div className='row'>
                <div className='row label-form'>
                  <div className='col-lg-6 col-md-3'>
                    <DropDownInput label="Hierarchy Type" required options={hierarchyTypeData} onChange={getWorkflowHierarchyTypeData} value={hierarchyType} />
                  </div>
                </div>
              </div>
            </div>
            <AgGridMemo data={hierarchyData} header={Hierarchy_Table_Column_Headings} height={"300px"} allBtnHide={true} onSelectionChanged={getData} viewOnly={true} singleRowSelect={true} hideFooter={true} />
          </>
          : null}
    </>
  )
}

export default User;