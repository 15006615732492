import Helper from "./Helper";

 
export const ValidateData = (Data) => {
    const found = Object.values(Data).every(value => {
        if (value === null || value === "" || value === undefined) {
            return true;
        }else {       
        return false;  
}})
    return found;
};

export const checkIfArrayIsEmpty = (arr) => {
    if (Array.isArray(arr)) {
      return arr.length === 0;
    }
    return false;
}

export const checkObjectProperties = (obj) => {
    for (const prop in obj) {
      if (obj[prop] !== null) {
        return true;
      }
    }
    return false;
}

export const isEmptyObject = (obj) => {
    if (obj === null || obj === undefined) {
      return true;
    }
    return Object.keys(obj).length === 0;
};

export const checkIdsInObject = (obj, ids) => {
  return ids.every(id => obj.hasOwnProperty(id));
};

export const getPopupMessageBasedOnStatus = (res) => {
    if (!res) return;
  
    if (res?.response?.status >= 500) {
      const errorMessage = res.response?.data?.message?.toLowerCase();
  
      if (errorMessage && errorMessage.includes('already exist')) {
        Helper.alert("This record is already exist.", 'error');
      } else {
        Helper.alert("Apologies, something went wrong from our side.", 'error');
      }
    } else {
      const message = res.response?.data?.message || 'An unexpected error occurred.';
      Helper.alert(message, 'error');
    }
}; 

export const checkObjectResponseCodes = (obj) => {
    const keys = Object.keys(obj);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const value = obj[key];
      if (value !== null && value === 500) {
        return false;
      }
    }
    return true;
}

export const ValidateArray = (dataArray) => { 
    let found;
    dataArray.forEach(element => {
        Object.values(element).every(value => {
            if (value === null || value === "" || value === undefined) {
                found = true;
        }else {       
            found = false;  
    }}) 
}); 
return found
}

//checks for inputType, and onChange field data
export const VallidateFields = (fieldData, inputType) => {
   let validate = false;
   if(inputType === "string" || inputType === "text" ){
    if(fieldData !== '' || fieldData !== null){
        validate = true
    }
   }  
   if(inputType === "number" || inputType === "integer" ){
    if(fieldData !== '' || fieldData !== null){
        validate = true
    }
   }  
   return validate;

}

export const objectIsEmpty = (obj) => {
  if (!obj) {
    return true;
  } else if (Array.isArray(obj) || typeof obj === "string") {
    return obj.length === 0;
  } else if (typeof obj === "object") {
    return Object.keys(obj).length === 0;
  } else {
    return false;
  }
};