import React, {  useEffect, useRef, useState } from 'react';
import './WorkflowHeader.css';
import MasterComponentWraper from '../../../components/Backend/MasterComponentWraper';
import NavigationHeder from '../../../components/Navigations/NavigationHeder'; 
import DropDownInput from '../../../components/Forms/Dropdown'; 
import PaginatedDropdown from '../../../components/Forms/PaginatedDropdown'; 
import CustomHtmlCell from '../../../components/grid/ag/cellRender/CustomHtmlCell';
import DatepickerFunctions from '../../../inc/DatepickerHelper';
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings'; 
import WorkflowOffCanvas from './WorkflowOffCanvas';
import AgGridNew from '../../../components/grid/ag/ag-grid-new'; 
import { getLabelFromDropdown, getOptions, optionsKeyCreator } from '../../../components/grid/ag/commonFunctions/GridCommonFunctions';

let currentSearchTermUser = '';
let sourcesAPICalled = false; 
let gridApi = null; 
const WorkflowPendingTasks = () => { 
  const [sources, setSources] = useState([]); 

  const [gridForceRender, setGridForceRender] = useState(1);
  const [workflowFetchTasksDetails, setWorkflowFetchTasksDetails] = useState({});

  const [showCanvas, setShowCanvas] = useState(false);

  const [workflowTasks, setWorkflowTasks] = useState({
    task_type_id: null,
    task_type_name: null,
    module_id: null, 
    workflow_name: null,
    stage: null,
    task_assignee_name: null,
    initiated_date: null
  });
    
  const usersDataRef = useRef([]);
  
  useEffect(() => {
    getSystemSources();
  }, []);

  const getSystemSources = () => {
    if(sourcesAPICalled) return;
    sourcesAPICalled = true;

    let api = Api
    api.setUserToken()
    api.axios().get(Settings.apiUrl+'/source',{}).then(function(res){
      if(res.data.status==='success'){
        setSources(res?.data?.data?.map((item) => {
          return {
            value: item.source_id,
            label: item.source_name
          }
        })) 
      } 
    })
  };
  
  const displayRecallBtn = () => { 
    return `<div style="background-color: #12BDB21C" class="rs_active_label_actions">Recall Action</div>`;
  };

  const displayReassignBtn = () => { 
    return `<div style="background-color: #54A0CA1C" class="rs_active_label_actions">Re-assign Action</div>`;
  };

  const onCellClickHandler = (event) => {
    const { module_id, workflow_name, stage, task_assignee_name, initiated_date, instance_task_id } = event?.data;
 
    if (event.colDef.field === 'recall') {
      setShowCanvas(true);
      setWorkflowTasks({
        module_id: module_id, 
        task_type_id: Settings.enum_workflow_data.task_types_recall.id,
        task_type_name: Settings.enum_workflow_data.task_types_recall.name,
        workflow_name: workflow_name,
        stage: stage,
        task_assignee_name: task_assignee_name,
        initiated_date: initiated_date,
        instance_task_id: instance_task_id
      })
    }

    if (event.colDef.field === 'reassign') {
      setShowCanvas(true);
      setWorkflowTasks({
        module_id: module_id, 
        task_type_id: Settings.enum_workflow_data.task_types_reassign.id,
        task_type_name: Settings.enum_workflow_data.task_types_reassign.name,
        workflow_name: workflow_name,
        stage: stage,
        task_assignee_name: task_assignee_name,
        initiated_date: initiated_date,
        instance_task_id: instance_task_id
      })
    }
  };
  
  const mapUsersData = (item) => {
    return {
      text: `${item.first_name} ${item.last_name}`,
      id: item.user_id
    };
  };
  
  const reloadGrid = () => {
    gridApi?.refreshServerSide({ purge: true });
    gridApi?.deselectAll();
  };
  
  const gridColHeaders = [
    { field:'document_number', valueFormatter: (params) => (params?.value ?? '-'), minWidth: 280, headerName: "Document number", editable: false },
    { field:'initiated_by_name', minWidth: 280, headerName: "Requester", editable: false },
    { field:'initiated_date', valueFormatter: (params) => DatepickerFunctions.convertDateFromDataBase(params?.value), minWidth: 280, headerName: "Date", editable: false },
    { field:'assigned_by_name', minWidth: 280, headerName: "From", editable: false },
    { field:'due_date', valueFormatter: (params) => DatepickerFunctions.convertDateFromDataBase(params?.value), minWidth: 280, headerName: "Due Date", editable: false},
    { 
      field: 'module_id', 
      headerName: 'Source',
      editable: false, 
      isAllowZero:true,
      minWidth: 280, 
      cellEditorParams: { values: sources },
      cellDataType:false,
      valueFormatter: (params)=> getLabelFromDropdown(params),
      filter:'agSetColumnFilter',
      filterParams: {
        values: (params) => getOptions(params, sources),
        keyCreator: (params) => optionsKeyCreator(params, 'value'),
        valueFormatter: params => params.value.label,
      }
    }, 
    { field:'recall', minWidth: 280, headerName: "Recall", valueFormatter: displayRecallBtn, cellRenderer: CustomHtmlCell, editable: false },
    { field:'reassign', minWidth: 280, headerName: "Re-assign", valueFormatter: displayReassignBtn, cellRenderer: CustomHtmlCell, editable: false }
  ];
  return (
    <div className='container-fluid'>
      <NavigationHeder
        hideBackButton={true}
        title={`<span style="color:#000;"> Home > </span>  ${"Workflow Pending Tasks"}`}
      />

      <div className="row">
        <div className='col-lg-3'>  
          <PaginatedDropdown
            options={[]} 
            pagination={true}
            apiUrl={Settings.apiUrl + `/user`}
            additionalParams={{user_type:0,adv_search:`[{"conditions":"AND","filters":[{"left":"enable","right":[true],"operation":"set"}]}]`}} 
            currentSearchTerm={currentSearchTermUser}
            allDataRef={usersDataRef}
            mapFunction={mapUsersData}
            reRenderRequired={true}
            placeHolder={"Select user"} 
            label={'User'}
            id="user_id"
            onChange={(evt) => {
              setWorkflowFetchTasksDetails((prevState) => {
                return {
                  ...prevState,
                  user_id: evt.target.value
                }
              })
              setGridForceRender(prevState => prevState + 1);
            }}
            value={workflowFetchTasksDetails?.user_id}
          /> 
        </div>

        <div className='col-lg-3'>  
          <DropDownInput
            options={ 
              Settings.enum_workflow_data.instance_state
            }
            required={true}
            reRenderRequired={true}
            placeHolder={"Select task type"} 
            label={'Task type'}
            id="task_type"
            onChange={(evt) => {
              setWorkflowFetchTasksDetails((prevState) => {
                return {
                  ...prevState,
                  task_type: evt.target.value
                }
              })
              setGridForceRender(prevState => prevState + 1);
            }}
            value={workflowFetchTasksDetails?.task_type}
          /> 
        </div> 
      </div>

      <AgGridNew 
        pagination={true} 
        apiUrl={Settings.apiUrl + `/workflow/pending_actions`} 
        payload={{
          task_type: workflowFetchTasksDetails?.task_type,
          for_workflow: true,
          user_id: workflowFetchTasksDetails?.user_id !== "" ? workflowFetchTasksDetails?.user_id : null
        }}
        returnApiCondition={!workflowFetchTasksDetails?.task_type || workflowFetchTasksDetails?.task_type === "" || workflowFetchTasksDetails?.task_type === undefined}
        apiMethod={"post"}
        key={gridForceRender}
        columnDefs={gridColHeaders} 
        onCellClicked={onCellClickHandler}
        hideDeleteBtn={true} 
        hideAddBtn={true}
        height={500}
        onGridReady={(params) => gridApi = params?.api} 
        gridId={'grid_workflow_pending_tasks'}
      />

      {showCanvas &&
        <WorkflowOffCanvas
          isOpen={showCanvas}
          sources={sources}
          type={{ label: workflowTasks?.task_type_name, value: workflowTasks?.task_type_id }}
          closeSidebar={setShowCanvas}
          reloadFunction={reloadGrid}
          workFlowActionObject={{
            workflow_name: workflowTasks?.workflow_name,
            stage: workflowTasks?.stage,
            task_assignee_name: workflowTasks?.task_assignee_name,
            initiated_date: workflowTasks?.initiated_date,
            instance_task_id: workflowTasks?.instance_task_id,
            user_id: workflowFetchTasksDetails?.user_id
          }}
          source_id={workflowTasks?.module_id} 
        />
      } 
    </div>
  );
}

export default (MasterComponentWraper(WorkflowPendingTasks));